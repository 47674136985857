import {Env} from "@/service/Environment";
import {ReportPrintTypes, ReportType} from "@/enum/report_type";
import {Sentry} from "@/service/Sentry";
import {Store} from "@/service/store/Store";
import {PrintType} from "@/enum/print_type";

let apiType = 'api';

if (Env.isElectron()) {
    if (window.electrowhale) {
        // ElectroWhale >= 5.3
        apiType = 'api';
    } else if (window.require) {
        // Electrowhale < 6
        apiType = 'ipcRenderer';
    } else {
        apiType = 'none';
        Sentry.captureException(`Unsupported ElectroWhale version detected, update this domain ASAP!`);
    }
} else {
    // Not in Electron
    apiType = 'none';
}

function callElectroWhale(method, ...params) {
    if (apiType === 'api') {
        const [domain, domainMethod] = method.split('.');
        return window.electrowhale.public[domain][domainMethod](...params);
    } else if (apiType === 'ipcRenderer') {
        return window.require('electron').ipcRenderer.invoke('public.' + method, ...params);
    } else {
        Sentry.captureException(`Unsupported ElectroWhale version detected, update this domain ASAP!`);
    }
}

const Electron = {

    version: null,

    async getVersion() {
        if (Env.isElectron()) {
            if (this.version) {
                return this.version;
            }
            return callElectroWhale('version.get')
                .then(version => {
                    window.console.info('ElectroWhale version ' + version);
                    const [majorVersion, minorVersion, patchVersion] = version.split('.');
                    Sentry.captureMessage('ElectroWhale version', {version, majorVersion, minorVersion, patchVersion});
                    this.version = version;
                    return version;
                }).catch(() => {
                    window.console.warn('Legacy ElectroWhale detected');
                    Sentry.captureMessage('ElectroWhale version', {version: '<=4', majorVersion: 4});
                    return false;
                });
        } else {
            return false;
        }
    },

    async setLang(lang) {
        if (Env.isElectron() && await this.getVersion() >= '5.0.0') {
            return callElectroWhale('lang.set', lang);
        } else {
            return false;
        }
    },

    async hasPrintConfigFor(mimeType) {
        if (Env.isElectron() && await this.getVersion() >= '5.1.0') {
            return callElectroWhale('print.isMimeTypeConfigured', mimeType);
        } else {
            return false;
        }
    },

    /**
     * @param preferredMimeType {ReportType.*}
     * @returns {Promise<null|ReportType.*>}
     */
    async getConfiguredMimeType(preferredMimeType) {
        if (Env.isElectron()) {
            let selectedMimeType = ReportType.PDF;
            if (await this.getVersion() >= '5.1.0') { // Determine preferred MimeType based on existing configuration
                if (await this.hasPrintConfigFor(preferredMimeType.mime)) {
                    selectedMimeType = preferredMimeType;
                } else {
                    let atLeastOne = false;
                    for (const type of ReportPrintTypes) {
                        const has = await this.hasPrintConfigFor(type.mime);
                        if (has) {
                            atLeastOne = true;
                            selectedMimeType = type;
                            break;
                        }
                    }
                    if (!atLeastOne) {
                        return null;
                    }
                }
            } else {
                // In older Electron, stick to PDF
            }
            return selectedMimeType;
        } else {
            window.console.error('Not in Electron!');
            throw new Error('Not in Electron!"');
        }
    },

    async print(url, filename, printType) {
        if (Env.isElectron()) {
            if (await this.getVersion() >= '5.0.0') {
                return callElectroWhale('print.byMimeType', url)
                    .then(() => {
                        Store.commit('snackbar/set', {text: 'base.print.sent'});
                    })
                    .catch(err => {
                        // TODO print just 'err' once https://github.com/electron/electron/issues/24427 is resolved
                        const split = (err.toString()).split('print.byMimeType');
                        const errMessage = split[1].slice(3) || split[0];

                        const printConfigRegex = /No print config for '([^']+)'!/;
                        let printConfigMatches = errMessage.match(printConfigRegex);

                        if (errMessage === "Error: Socket timeout") {
                            Store.commit('snackbar/set', {text: 'base.print.socketTimeout', timeout: -1});
                        } else if (printConfigMatches && printConfigMatches[1]) {
                            Store.commit('snackbar/set', {
                                text: 'base.print.noConfigFor',
                                params: [printConfigMatches[1]],
                                timeout: -1
                            });
                        } else {
                            Store.commit('snackbar/set', {text: errMessage, timeout: -1});
                        }
                    });
            } else {
                const messageBus = window.require('electron').ipcRenderer;
                const method = {
                    [PrintType.PDF]: 'system.printPdf',
                    [PrintType.ZPL]: 'system.printLabelZebra',
                    [PrintType.ESCP]: 'system.printLabelBrother',
                    [PrintType.ZPLX]: 'system.printLabelZebraZplx',
                    [PrintType.ZPLX2]: 'system.printLabelZebraZplx2',
                    [PrintType.ZPLXM]: 'system.printLabelZebraZplxm',
                }[printType];
                if (method) {
                    messageBus.send(method, url);
                    if (printType === PrintType.PDF) {
                        // Fallback for ancient Electron. Drop this in the future
                        messageBus.send('system.printUrl', url, /* options: */ undefined, /* PDF: */ true);
                    }
                    Store.commit('snackbar/set', {text: 'base.print.sent'});
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            return false;
        }
    },
};

export {Electron};
